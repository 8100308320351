import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

interface TabContextType {
}

const TabsContext = createContext<TabContextType | null>(null);

const TabsProvider = ({ children }: PropsWithChildren) => {
  const tabsContext = useMemo(() => ({}), []);

  return <TabsContext.Provider value={tabsContext}>{children}</TabsContext.Provider>;
};

export const useTabs = () => useContext(TabsContext);

export default TabsProvider;