import { Grid, LinearProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { upload } from "../../modules/tools/assets/api";
import { FileHeader } from "./FileHeader";

import { UploadFileProps } from "./UploadError";

type Props = Omit<UploadFileProps, "errors"> & {
  onUpload: (file: File) => void,
};

export const SingleFileUploadProgress: FC<Props> = ({ file, onDelete, onUpload }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    (async () => {
      await upload(file, setProgress);

      onUpload(file);
    })();
  }, []);

  return (
    <Grid item>
      <FileHeader file={file} onDelete={onDelete} />
      <LinearProgress variant="determinate" value={progress} />
    </Grid>
  );
};
