import { useFormik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const changePasswordSchema = Yup.object().shape({
  password: Yup.string().min(6).required('Password is required'),
  passwordVerify: Yup.string().min(6).required('You must verify password to continue'),
});

const PasswordReset = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordVerify: ''
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting, }) => {
      try {
        
      } catch (error: any) {
        setStatus(error.response?.data?.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">Reset your password</h1>
        <div className="text-gray-500 fw-semibold fs-6">
          Please, provide a new password to continue
        </div>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
        <input
          type="password"
          placeholder="Password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">Re-type password</label>
        <input
          type="password"
          placeholder="Re-type password"
          autoComplete="off"
          {...formik.getFieldProps('passwordVerify')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.passwordVerify && formik.errors.passwordVerify
            },
            {
              'is-valid': formik.touched.passwordVerify && !formik.errors.passwordVerify
            }
          )}
        />
        {formik.touched.passwordVerify && formik.errors.passwordVerify && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.passwordVerify}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="d-flex flex-column gap-5 mb-10">
        <Button
          type="submit"
          className="btn btn-primary"
          endIcon={<ArrowForwardIos/>}
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
        >
          {!formik.isSubmitting && <span className="indicator-label">Continue</span>}
          {formik.isSubmitting && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
        <Button
          type="button"
          className="btn btn-danger"
          onClick={() => navigate('/auth/login')}
          startIcon={<ArrowBackIos/>}
        >Back to Login</Button>
      </div>
      {/* end::Action */}
    </form>
  );
};

export default PasswordReset;
