import { lazy } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../core';
import { SuspenseView } from '../../routing/PrivateRoutes';

const ThirdPartiesPage = () => {
  const CustomersPage = lazy(() => import('./customers/CustomersPage'));
  const DivisionsPage = lazy(() => import('./divisions/DivisionsPage'));
  const WarehousesPage = lazy(() => import('./warehouse/WarehousesPage'));

  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Third-Parties',
      path: '/third-parties',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="customers/*"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Customers List</PageTitle>
              <SuspenseView>
                <CustomersPage />
              </SuspenseView>
            </>
          }
        />
        <Route
          path="division/*"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Divisions List</PageTitle>
              <SuspenseView>
                <DivisionsPage />
              </SuspenseView>
            </>
          }
        />
        <Route
          path="warehouses/*"
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Warehouses</PageTitle>
              <SuspenseView>
                <WarehousesPage />
              </SuspenseView>
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to="/third-parties/customers" />} />
    </Routes>
  );
};

export default ThirdPartiesPage;