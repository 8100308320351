import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import CardSnippet from '../../../../../components/Cards/snippet';

interface ImportFormProps {
  onCloseAction: () => void;
  onSubmitAction: (values: FieldValues) => Promise<void> | void;
}

export const ImportForm: FC<ImportFormProps> = ({
  onCloseAction,
  onSubmitAction,
}) => {
  const actions = [
    { name: 'insertOrUpdate', label: 'Insert, on conflict update' },
    { name: 'insert', label: 'Insert, on conflict stop and rollback', disabled: true },
    { name: 'update', label: 'Update by replacing existing data', disabled: true },
  ];
  const onDrop = useCallback(async (selected: File[]) => {
    try {
      setValue('file', selected[0]);
    } catch (error: any) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { open } = useDropzone({ onDrop, onFileDialogCancel: onCloseAction });
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      action: 'insertOrUpdate',
    },
    mode: 'onChange',
    resolver: yupResolver(
      yup.object({
        action: yup.string().required(),
        file: yup.mixed(),
      })
    ),
  });

  useEffect(() => {
    open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        try {
          console.log(values);
          onSubmitAction(values);

          onCloseAction();
        } catch (error: any) {
          console.log(error);
        }
      })}
    >
      <CardSnippet sx={{ mt: 5 }} title="File Info">
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={4}>
            <Typography align="right" fontWeight="fontWeightBold" mr={5}>
              File name:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography noWrap>
              {getValues('file') ? (getValues('file') as File).name : 'Not file selected'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="right" fontWeight="fontWeightBold" mr={5}>
              File type:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{getValues('file') ? (getValues('file') as File).type : ''}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="right" fontWeight="fontWeightBold" mr={5}>
              File size:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{`${Number(getValues('file') ? (getValues('file') as File).size / 1024 : 0).toLocaleString(
              'default',
              { maximumFractionDigits: 2 }
            )}KB`}</Typography>
          </Grid>
        </Grid>
      </CardSnippet>
      <CardSnippet sx={{ mt: 5 }} title="Import options">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <FormControl>
              <Controller
                control={control}
                name="action"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup
                    aria-label="action"
                    value={value}
                    name="action"
                    sx={{ mt: 1 }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onChange(e);
                      setValue('action', e.target.value);
                    }}
                  >
                    {actions.map((action, index) => (
                      <FormControlLabel
                        control={<Radio />}
                        disabled={action.disabled}
                        key={`import-action-${index}`}
                        label={action.label}
                        value={action.name}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardSnippet>

      <Grid container spacing={5} mt={10}>
        <Grid item xs={6}>
          <Button
            disabled={isSubmitting}
            type="submit"
            fullWidth
            variant="contained"
            size="large"
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={isSubmitting}
            color="error"
            fullWidth
            onClick={onCloseAction}
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ImportForm;
