import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { useDataTableData, useDataTableDimensions } from "./DataTableProvider";
import { Body } from './Partials';

export const DataTableContainer = () => {
  const { columns, rows } = useDataTableData();
  const dimensions = useDataTableDimensions();
  const width = dimensions?.table?.width;
  const isLoading = false;

  return (
    <>
      <ScrollSync>
        <div style={{ width }}>
          <div className="datatable">
            {columns.length < 0 && (
              <>
                {/*<Header />*/}
                {rows.length > 0 && !isLoading && (<Body />)}
              </>
            )}
            {(columns.length > 0 && rows.length > 0) && !isLoading && (
              <>
                <div className="no-data">
                  <ScrollSyncPane>
                    <div
                      style={{
                        // height: height - 15, width: width - 15,
                        overflowY: 'hidden',
                        height: '15px',
                      }}
                    >
                      <div>
                        .
                      </div>
                    </div>
                  </ScrollSyncPane>
                </div>
              </>
            )}
            {(!columns.length || !rows.length) && (
              <div className="no-data">There is no data yet!</div>
            )}
          </div>
        </div>
      </ScrollSync>
    </>
  );
};
