import { FC } from "react";

import { SearchWidget } from "./SearchWidget";

type Props = {
  disabled?: boolean,
  title: string,
  enableSearch?: boolean,
  enableFilter?: boolean,
  enableDownload?: boolean,
  enablePrint?: boolean,
  enableSaveConfiguration?: boolean,
  height?: string,
  width?: string,
};

export const Header: FC<Props> = (
  {
    disabled = false,
    title,
    enableSearch = true,
    enableFilter = true,
    enableDownload = true,
    enablePrint = true,
    width = "100%",
    height = "100%"
  }
) => {
  return (
    <div style={{ height, width, background: "black", color: "white" }}>DataTable Header {width} {height}
      <div className="title">{title}</div>
      {enableSearch && <SearchWidget disabled={disabled} />}
      {enableDownload && <>Enable Download: {JSON.stringify(enableDownload)}</>}
      {enableFilter && <>Enable Filter: {JSON.stringify(enableFilter)}</>}
      {enablePrint && <>Enable Print: {JSON.stringify(enablePrint)}</>}
    </div>
  );
};