import { Chip, Grid } from '@mui/material';
import { FC } from 'react';

import { Option } from '../../Prototypes';

type Props = {
  id: string,
  options: Option[];
};

const OptionListCell: FC<Props> = ({ options }) => {
  return(
    <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
      {options.map((option, index) => (
        <Chip key={`status-${index}`} label={option.label}/>
      ))}
    </Grid>
  );
}

export default OptionListCell;
