import {
  Box,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell, TableContainer, TableFooter,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { OptionsMenu } from "../../../../core";
import Icon from "../../../../core/components/Icon";
import { initialState } from '../../../models';
import { BillingSummary, getInvoiced } from "../../../modules/billing";
import { RequestProvider, ResponseProvider, useRequest, useResponseData, useResponseLoading } from '../../../providers';

type InvoiceWidgetProps = {
  title?: string,
};

const InvoicedWidgetWrapper: FC<InvoiceWidgetProps> = ({ title }) => {
  const { updateState } = useRequest();
  useResponseLoading();
  const rows: BillingSummary[] = useResponseData();

  const [current, setCurrent] = useState<string>("year");
  const totalCurrent = rows.reduce((a, b) => a + Number(b.current.replace(/[^0-9.-]+/g, "")), 0);
  const totalPrior = rows.reduce((a, b) => a + Number(b.prior.replace(/[^0-9.-]+/g, "")), 0);
  const totalVariance = (totalCurrent - totalPrior) / totalPrior;

  const setSelected = (row: BillingSummary) => {
    switch (current) {
      case "day":
        return row.day === new Date().getDate();
      case "month":
        return row.month === new Date().getMonth() + 1;
      case "year":
        return row.label === "YTD";
      default:
        return false;
    }
  };

  useEffect(() => {
    (async () => {
      updateState({ filter: { [current]: true }, ...initialState });
    })();

  }, [current]);

  return (
    <Card>
      <CardHeader
        action={
          <OptionsMenu
            options={[
              {
                icon: <Icon icon={`mdi:radiobox-${current === "year" ? "marked" : "blank"}`} />,
                menuItemProps: {
                  onClick: () => setCurrent("year"),
                  selected: current === "year"
                },
                text: "By Year"
              },
              {
                icon: <Icon icon={`mdi:radiobox-${current === "month" ? "marked" : "blank"}`} />,
                menuItemProps: {
                  onClick: () => setCurrent("month"),
                  selected: current === "month"
                },
                text: "By Month"
              },
              {
                icon: <Icon icon={`mdi:radiobox-${current === "day" ? "marked" : "blank"}`} />,
                text: "By Day",
                menuItemProps: {
                  onClick: () => setCurrent("day"),
                  selected: current === "day"
                }
              },
              { divider: true },
              {
                icon: <Icon icon={`mdi:radiobox-${current === "customer" ? "marked" : "blank"}`} />,
                menuItemProps: {
                  onClick: () => setCurrent("customer"),
                  selected: current === "customer"
                },
                text: "By Customer"
              },
              {
                icon: <Icon icon={`mdi:radiobox-${current === "division" ? "marked" : "blank"}`} />,
                menuItemProps: {
                  onClick: () => setCurrent("division"),
                  selected: current === "division"
                },
                text: "By Division"
              }
            ]}
          />
        }
        title={title}
        titleTypographyProps={{
          sx: {
            lineHeight: "1.5rem !important",
            letterSpacing: "0.15px !important"
          }
        }}
        subheader={(
          <Typography className="mr-3">
            {current === "customer" || current === "division" ? `Year ` : (
              <Typography
                component="span"
                display="inline"
                sx={{
                  fontWeight: "bold",
                  textDecoration: "underline"
                }}
              >
                {capitalize(current)}
              </Typography>
            )}
            {" To Date Invoiced "}
            {current === "customer" || current === "division" ? (
              <>
                {"By "}
                <Typography
                  component="span"
                  display="inline"
                  sx={{
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  {capitalize(current)}
                </Typography>
              </>
            ) : ""}
          </Typography>
        )}
      />
      <Divider />

      <CardContent>
        <Box sx={{ mb: 3.75, display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4" sx={{ mr: 3.5 }}>
              {" "}
            </Typography>
          </Box>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>
                    {(current === "customer"
                      ? current.slice(0, 4)
                      : current === "division"
                        ? current.slice(0, 3)
                        : current === "year"
                          ? ""
                          : current).toUpperCase()
                    }
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>CURRENT</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>PRIOR</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>VAR</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableRow
                    key={`row-${index}`}
                    selected={setSelected(row)}
                  >
                    <TableCell>
                      <Typography>{row.label}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {row.current}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color="gray">
                        {row.prior}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color={row?.color}>
                        {row?.variance}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
              {!rows.length && (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Typography align="center">No rows!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {current !== "year" && (
              <TableFooter>
                <TableRow>
                  <TableCell align="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px"
                      }}>
                      TOTALS
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{
                      fontWeight: "bold",
                      fontSize: "12px"
                    }}>
                      {totalCurrent.toLocaleString("default", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{
                      fontWeight: "bold",
                      fontSize: "12px"
                    }}>
                      {totalPrior.toLocaleString("default", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color={totalVariance < 0
                        ? "error"
                        : totalVariance > 0
                          ? "green"
                          : "warning"}
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px"
                      }}
                    >
                      {totalVariance.toLocaleString("default", {
                        style: "percent",
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export const InvoicedWidget: FC<InvoiceWidgetProps> = ({ title = "Invoiced Widget" }) => {
  return (
    <RequestProvider>
      <ResponseProvider callback={getInvoiced} queryKey="billing-invoiced">
        <InvoicedWidgetWrapper title={title} />
      </ResponseProvider>
    </RequestProvider>
  );
};

export default InvoicedWidget;
