import clsx from 'clsx';

import { useAuth } from '../../../../app/modules/auth';
import { useSettings } from '../../../../core';
import CurrentUserAction from '../../../../core/layouts/components/shared/CurrentUserAction';
import ThemeModeAction from '../../../../core/layouts/components/shared/ThemeModeAction';
// import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials';
import { useLayout } from '../../core';
import { SVGImage } from '../../../../app';
// import { toAbsoluteUrl } from '../../../helpers';
import { Box } from '@mui/material';

const itemClass = 'ms-1 ms-lg-3';
// const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
// const userAvatarClass = 'symbol-35px symbol-md-40px';
const btnIconClass = 'svg-icon-1';

export const Navbar = () => {
  const { settings, save } = useSettings();
  const { currentUser } = useAuth();
  // const picture =
  //   currentUser?.avatar || toAbsoluteUrl('/media/avatars/no-image.png');
  const { config } = useLayout();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        {/*<Search/>*/}
      </div>

      {/*<div className={clsx('app-navbar-item', itemClass)}>*/}
      {/*  <div id='kt_activities_toggle' className={btnClass}>*/}
      {/*    <SVGImage path='/media/icons/duotone/general/gen032.svg' className={btnIconClass} />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className={clsx('app-navbar-item', itemClass)}>*/}
      {/*  <div*/}
      {/*    data-kt-menu-trigger="{default: 'click'}"*/}
      {/*    data-kt-menu-attach='parent'*/}
      {/*    data-kt-menu-placement='bottom-end'*/}
      {/*    className={btnClass}*/}
      {/*  >*/}
      {/*    <SVGImage path='/media/icons/duotone/general/gen022.svg' className={btnIconClass} />*/}
      {/*  </div>*/}
      {/*  <HeaderNotificationsMenu />*/}
      {/*</div>*/}

      {/*<div className={clsx('app-navbar-item', itemClass)}>*/}
      {/*  <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>*/}
      {/*    <SVGImage path='/media/icons/duotone/communication/com012.svg' className={btnIconClass} />*/}
      {/*    <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeAction
          saveSettings={(values) => save?.(values)}
          settings={settings}
        />
      </div>

      {currentUser && (
        <>
          <CurrentUserAction settings={settings} />
        </>
      )}

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <SVGImage
              path="/media/icons/duotune/text/txt001.svg"
              className={btnIconClass}
            />
          </div>
        </div>
      )}
    </Box>
  );
};
