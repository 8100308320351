import { ColumnInstance } from 'react-table';

interface ColumnHeaderProps<T extends object> {
  column: ColumnInstance<T>;
}

export const ColumnHeader = ({ column }: ColumnHeaderProps<any>) => (
  <>
    {column.Header && typeof column.Header === 'string'
      ? (<th {...column.getHeaderProps()}>{column.render('Header')}</th>)
      : column.render('Header')}
  </>
);