import { useLayout } from '../../core';
import { Link } from 'react-router-dom';

export const SidebarFooter = () => {
  const { config } = useLayout();
  const sidebar = config.app?.sidebar;

  return (
    <div className={`${!sidebar?.display ? 'hidden' : 'block'} app-sidebar-footer flex-column-auto pt-2 pb-6 px-6`}>
      <Link
        to="https://axnygoup.com/about"
        target="_blank"
        rel="noreferrer"
        className="d-flex align-items-center text-center text-xs text-gray-500"
      >
        &copy; {new Date(Date.now()).getFullYear()} – American Exchange Group
      </Link>
    </div>
  );
};
