import { Check as CheckIcon, Close as CloseIcon, Dangerous as DangerousIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { emailVerification } from '../../../api';
import { QUERIES } from '../../../constants';

export const EmailVerification = () => {
  const query = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  let token = query.get('token');
  const { error, isError, isLoading, isSuccess } = useQuery(`${QUERIES.USERS}`, async () => {
    return emailVerification(String(token));
  }, { refetchOnWindowFocus: false });

  console.log((error as any)?.response?.data?.message);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Email account verification</h1>

        <div className='text-gray-500 fw-semibold fs-6'>
          Please wait your account is being analyzed.
        </div>
      </div>

      <Box sx={{
        mt: 10,
        mb: 10,
        position: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyItems: 'center',
        verticalAlign: 'middle',
      }}>
        <Typography fontSize={14}>
          {isLoading && (<div className="text-gray-500 fw-semibold fs-6">
            <CircularProgress size={16} />
            {' '}
            Please wait...
          </div>)}

          {isSuccess && (
            <div className="mb-lg-15 alert alert-info">
              <div className="alert-text font-weight-bold">
                <CheckIcon color="primary" />
                {' '}
                Account has been successfully verified!
              </div>
            </div>
          )}

          {isError && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">
                <DangerousIcon color="error" />
                {error
                  ? (error as any)?.response?.data?.message
                  : 'Sorry, looks like there are some errors detected, please try again.'}
              </div>
            </div>
          )}
        </Typography>
      </Box>


      <Box className="d-flex flex-wrap justify-content-center pb-lg-0">
        <Button
          type="button"
          disabled={isLoading}
          variant="outlined"
          color="error"
          className="me-4"
          onClick={() => {
            navigate('/auth/login');
          }}
          startIcon={<CloseIcon />}
        >
          Go to Login Page
        </Button>
      </Box>
    </Box >
  )
}
