import { Row } from 'react-table';
import clsx from 'clsx';

interface TableRowProps<T extends object> {
  row: Row<T>;
}

export const TableRow = ({ row }: TableRowProps<any>) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => (
        <td
          {...cell.getCellProps()}
          className={clsx({ 'text-end min-w-10px': cell.column.id === 'actions' || cell.column.id === 'selection' })}
        >{cell.render('Cell')}</td>
      ))}
    </tr>
  );
};
