import { Close as CloseIcon, Send as SendIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { passwordRecovery } from '../../api';
import { LoadingButton } from '@mui/lab';

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  return (
    <Formik
      initialValues={{
        email: 'isidro.lopezg@live.com',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Wrong email format')
          .min(3, 'Minimum 3 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Email address is required'),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        setLoading(true);
        setHasErrors(undefined);

        try {
          await passwordRecovery(values.email);

          setHasErrors(false);
          setLoading(false);
        } catch (error: any) {
          if (!error?.response) {
            setStatus('No server response')
          } else if (error.response?.status === 404) {
            setStatus(error.response?.data?.message);
          } else {
            setStatus('The login details are incorrect');
          }
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
        }
      }}
    >
      {({ errors, getFieldProps, isSubmitting, isValid, status, touched }) => {
        return (
          <Form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
            <div className='text-center mb-10'>
              <h1 className='text-dark fw-bolder mb-3'>Forgot Password?</h1>

              <div className='text-gray-500 fw-semibold fs-6'>
                Enter your user account's verified email address and we will send you a password reset link.
              </div>
            </div>

            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  {status}
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Sent password reset. Please check your email</div>
              </div>
            )}

            {hasErrors === undefined && <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
              <input
                type='email'
                placeholder='Enter your email address'
                autoComplete='off'
                {...getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': touched.email && errors.email },
                  {
                    'is-valid': touched.email && !errors.email,
                  }
                )}
              />
              {touched.email && errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.email}</span>
                  </div>
                </div>
              )}
            </div>}

            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <Button
                type="button"
                variant="outlined"
                color="error"
                className="me-4"
                onClick={() => {
                  setHasErrors(undefined);
                  navigate('/auth/login');
                }}
                startIcon={<CloseIcon />}
              >
                Back to Login Page
              </Button>
              {hasErrors === undefined && <LoadingButton
                type="submit"
                color="primary"
                endIcon={<SendIcon />}
                loading={isSubmitting}
                loadingPosition="end"
                variant="contained"
                disabled={!String(touched?.email).length || isSubmitting || !isValid}
              >
                <span className='indicator-label'>Reset password</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </LoadingButton>}
            </div>
          </Form>
        );
      }}
    </Formik>
  )
};

export default PasswordRecovery;
