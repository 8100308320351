import { Send as SendIcon, Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'

import { resendConfirmationLink } from '../../../api';


export function ResendConfirmationLink() {
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Wrong email format')
          .min(3, 'Minimum 3 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Email address is required'),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        setSubmitting(true);

        try {
          await resendConfirmationLink(values.email);
          setHasErrors(false);
        } catch (error: any) {
          setHasErrors(true);
          setStatus(error.message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ errors, getFieldProps, isSubmitting, isValid, touched }) => {
        return (
          <Form
            className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          >
            <div className="text-center mb-10">
              <h1 className="text-dark fw-bolder mb-3">Resend Email Confirmation Link</h1>

              <div className="text-gray-500 fw-semibold fs-6">
                Enter your email address and we will send you a new confirmation link.
              </div>
            </div>

            {hasErrors === true && (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  Sorry, looks like there are some errors detected, please try again.
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className="mb-10 bg-light-info p-8 rounded">
                <div className="text-info">Sent an email with instructions to confirm your account. Please check your email box.</div>
              </div>
            )}

            <div className="fv-row mb-8">
              <input
                type="email"
                placeholder="Enter your email address"
                autoComplete="off"
                autoFocus
                {...getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': touched.email && errors.email },
                  {
                    'is-valid': touched.email && !errors.email,
                  }
                )}
              />
              {touched.email && errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex flex-wrap justify-content-center pb-lg-0">
              <Link to='/auth/login'>
                <Button
                  type="button"
                  className="me-4"
                  color="error"
                  startIcon={<CloseIcon />}
                  variant="outlined"
                >
                  Back to Login
                </Button>
              </Link>
              <LoadingButton
                type="submit"
                disabled={!String(touched?.email).length || isSubmitting || !isValid}
                endIcon={<SendIcon />}
                loading={isSubmitting}
                loadingPosition="end"
                variant="contained"
                color="primary"
              >
                Submit
              </LoadingButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  )
}
