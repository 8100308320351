import { Search as SearchIcon } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { FC } from "react";
import { BaseButton } from "../../Buttons/BaseButton";

type Props = {
  disabled?: boolean,
  placeholder?: string,
  searchTerm?: string,
};

export const SearchWidget: FC<Props> = (
  {
    disabled = false,
    placeholder = "Search...",
    searchTerm,
  }
) => {
  const setTerm = (value: string) => {};

  return (
    <>
      <TextField
        className={`search-input`}
        onChange={(e) => setTerm(e.target.value)}
        value={searchTerm}
      />
      <BaseButton title={placeholder} disabled={disabled} icon={<SearchIcon />} onlyIcon />
    </>
  );
};
