import { PropsWithChildren, useState } from 'react';
import { Table } from '../table';
import { Tabs } from '../Tabs';

interface Tab<T extends {}> {
  title: string;
  data: T[];
}

interface TabbedCardProps extends PropsWithChildren {
  title: string;
  tabs?: Tab<{}>[];
}

export interface TabProps extends PropsWithChildren {
  active?: boolean;
  ariaLabelledBy?: string;
  id?: string;
}

export const Tab = ({ active, ariaLabelledBy, id, children }: TabProps) => {
  ariaLabelledBy = ariaLabelledBy || id;

  return (
    <div
      aria-labelledby={ariaLabelledBy}
      className={`tab-pane fade ${active ? 'show active' : ''}`}
      data-toggle="tab"
      id={id}
      role="tabpanel"
    >{children}</div>
  );
};

export const TabbedCard = ({ children, title, tabs = [] }: TabbedCardProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  // const toggleTab = (tab: number) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  return (
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <h3 className="card-label">{title}</h3>
        </div>
        <div className="card-toolbar">
          <ul className="nav nav-bold nav-pills">
            {tabs.map((tab, index) => (
              <li key={`tab-item-${index}`} className="nav-item">
                <a
                  className={`nav-link ${activeTab === index ? 'active' : ''}`}
                  data-toggle="tab"
                  href={`#tab-content-${index}`}
                >
                  {tab.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="card-body">
        <div className="tab-content">
          {children ? children : (
            tabs.map((tab, index) => (
              <Tab active={activeTab === index} id={`tab-content-${index}`} key={`tab-content-${index}`}>
                <Table key={`tab-table-${index}`} data={tab.data} id={`tab-content-table-${index}`}/>
              </Tab>
            ))
          )}
          <Tabs />
        </div>
      </div>
    </div>
  );
};