import { Card } from '@mui/material';
import { useMemo } from 'react';
import { useTable } from 'react-table';
import { ColumnHeader } from './ColumnHeader';
import { TableRow } from './TableRow';

interface TableProps<T extends object> {
  id?: string;
  columns?: any;
  data: T[];
}

export const Table = ({ columns = [], data = [], id }: TableProps<{}>) => {
  const tableRows = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => {
    return columns && columns.length
      ? columns
      : Object.keys(data[0] || {}).map((key) => ({ Header: key, accessor: key }));
  } , [columns, data]);
  const { getTableProps, getTableBodyProps, headers, prepareRow, rows } = useTable({ columns: tableColumns, data: tableRows });

  return (
    <Card className="py-4">
      <div className="table-responsive mx-2">
        <table
          className="table align-middle table-striped table-row-dashed fs-6 gy-5 dataTable no-footer"
          id={id}
          {...getTableProps()}
        >
          <thead>
          <tr className="text-start align-middle text-muted text-uppercase fw-bolder fs-7 gs-0">
            {headers.map((column) => (
              <ColumnHeader key={column.id} column={column} />
            ))}
          </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
          {rows.length
            ? (
              rows.map((row, index) => {
                prepareRow(row);

                return <TableRow key={`row-${index}-${row.id}`} row={row}/>;
              })
            )
            : (
              <tr>
                <td colSpan={headers.length}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    No matching records found!
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};