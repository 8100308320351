import { Alert, AlertTitle, Button, Link as LinkButton } from '@mui/material';
import { useGoogleLogin } from "@react-oauth/google";
import clsx from 'clsx';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import * as Yup from 'yup';

import { getUserByToken, googleLogin, login } from '../api';
import { useAuth } from '../core/Auth';
import { SVGImage } from "../../../components";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAuth, setCurrentUser } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const auth = await login(values.email, values.password);
        setAuth(auth);
        const user = await getUserByToken();
        setCurrentUser(user);
      } catch (error: any) {
        if (!error?.response) {
          setStatus('No server response')
        } else if (error?.response?.status) {
          if (error.response?.data?.status === 403) {
            setConfirm(true);
          }
          setStatus(error.response?.data?.message);
        } else {
          setStatus('Unknown Server Error');
        }
        setAuth(undefined);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const auth = await googleLogin({ code });
      setAuth(auth);
      const user = await getUserByToken();
      setCurrentUser(user);
    },
    flow: 'auth-code',
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="login_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
        <div className="text-gray-500 fw-semibold fs-6">With your social networks</div>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      <div className="row g-3 mb-9">
        <div className="col-md-12">
          <Button
            variant="outlined"
            color="error"
            fullWidth
            onClick={loginWithGoogle}
            className="btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap"
          >
            <SVGImage path="/media/svg/brand-logos/google-icon.svg" className="me-2" />
            Sign in with Google
          </Button>
        </div>
      </div>
      {/* end::Login options */}

      {/* begin::Separator */}
      <div className="separator separator-content my-14">
        <span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
      </div>
      {/* end::Separator */}

      {formik.status ? (
        <Alert severity="error" sx={{ mb: '10px', fontSize: '12px' }}>
          <AlertTitle className="text-uppercase fw-bold">{formik.status}</AlertTitle>
          Check you email box or
          {' '}
          {confirm && (
            <LinkButton
              type="button"
              component="button"
              onClick={() => {
                navigate('/auth/email/resend/confirmation')
              }}
            >Request a new verification link</LinkButton>
          )}
        </Alert>
      ) : (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">
            Type your account <strong>user</strong> and <strong>password</strong> to
            continue.
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            },
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
        <input
          type="password"
          placeholder="Password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            },
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />

        {/* begin::Link */}
        <Link to="/auth/password/recovery" className="link-primary">
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className="text-gray-500 text-center fw-semibold fs-6">
        Not a Member yet?{' '}
        <Link to="/auth/registration" className="link-primary">
          Sign up
        </Link>
      </div>
    </form>
  );
}
