import {
  Card,
  CardContent,
  CardHeader, Collapse,
  Divider,
  FormControl,
  Grid, IconButton,
  InputLabel,
  Select, Table, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { DataGrid as BaseGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { FC, ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { SVGImage } from '../../../app';
import { PurchaseOrder } from '../../../app/modules/orders/models';
import { useResponseData, useResponseLoading } from '../../../app/providers';
import Avatar from '../Avatar/Avatar';
import { StatisticCard, StatisticCardProps } from '../Card';
import Icon from '../Icon';
import DataGridHeader from './DataGridHeader';

export type DataGridProps = {
  stats?: StatisticCardProps[] | ReactNode,
};

export const DataGrid: FC<DataGridProps> = ({ stats = [] }) => {
  useResponseLoading();
  const rows: PurchaseOrder[] = useResponseData();

  const [value, setValue] = useState<string>('');
  const [addUserOpen, setAddUserOpen] = useState<boolean>(false);
  const [cellIndex, setCellIndex] = useState<number>(-1);
  // const [open, setOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: '',
      renderCell: (values: GridRenderCellParams<any>) => {
        return (
          <IconButton
            onClick={() => setCellIndex(cellIndex === values.value ? -1 : values.value)}
          >
            <Icon icon={values.value === cellIndex ? 'mdi:chevron-down' : 'mdi:chevron-right'} />
          </IconButton>
        );
      },
      width: 60,
    },
    {
      field: 'po',
      headerName: 'PO',
      renderCell: (cell: GridRenderCellParams<PurchaseOrder>) => {
        console.log(cell);
        return (
          <Grid>
            <Avatar>
              <SVGImage
                path={`/media/icons/regular/${
                  cell.row.status === 'OPEN'
                    ? 'box-open'
                    : 'ship'
                }`}
              />
            </Avatar>
            <Typography>{cell.value}</Typography>
            <Collapse in={cell.id === cellIndex} timeout="auto" unmountOnExit>
              <Grid container>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{cell.id}-1</TableCell>
                      <TableCell>{cell.id}-2</TableCell>
                      <TableCell>{cell.id}-3</TableCell>
                      <TableCell>{cell.id}-4</TableCell>
                      <TableCell>{cell.id}-5</TableCell>
                      <TableCell>{cell.id}-6</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Grid>
            </Collapse>
          </Grid>
        );
      },
      width: 100,
    },
    {
      field: 'customer',
      headerName: 'CUST',
      renderCell: (cell: GridRenderCellParams<any>) => {
        return (<Link to={`/third-parties/customers/${cell.value}`}>
          {cell.value}
        </Link>);
      },
      width: 60,
    },
    {
      field: 'division',
      headerName: 'DIV',
      renderCell: (cell: GridRenderCellParams<any>) => {
        return (<Link to={`/third-parties/divisions/${cell.value}`}>
          {cell.value}
        </Link>);
      },
      width: 60,
    },
    {
      field: 'warehouse',
      headerName: 'WH',
      renderCell: (cell: GridRenderCellParams<any>) => {
        return (<Link to={`/third-parties/warehouses/${cell.value}`}>
          {cell.value}
        </Link>);
      },
      width: 60,
    },
    {
      field: 'startDate',
      headerName: 'S. DATE',
      renderCell: (cell: GridRenderCellParams<any>) => {
        return (<Typography>{cell.value}</Typography>);
      },
      width: 60,
    },
  ];

  const handleFilter = useCallback((value: string) => {
    setValue(value);
  }, []);

  const toggleAddUserDrawer = () => setAddUserOpen(!addUserOpen);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        {stats && (
          <Grid container spacing={6}>
            {(stats as StatisticCardProps[]).map((statistic, index) => (
              <Grid item xs={12} md={3} sm={6} key={`statistic-widget-${index}`}>
                <StatisticCard
                  {...statistic}
                  icon={<SVGImage
                    path={`/media/icons/regular/${statistic.icon as string}.svg`}
                    className={`svg-icon-1 text-${statistic.color === 'error' ? 'danger' : statistic.color}`}
                  />}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Search Filters"
            sx={{ pb: 4, '& .MuiCardHeader-title': { letterSpacing: '.15px' } }}
          />

          <CardContent>
            <Grid container spacing={6}>
              <Grid item sm={3} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-customer-label">Select Customer</InputLabel>
                  <Select
                    fullWidth
                  ></Select>
                </FormControl>
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-customer-label">Select Division</InputLabel>
                  <Select
                    fullWidth
                  ></Select>
                </FormControl>
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-customer-label">Select Warehouse</InputLabel>
                  <Select
                    fullWidth
                  ></Select>
                </FormControl>
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-customer-label">Select Status</InputLabel>
                  <Select
                    fullWidth
                  ></Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />

          <DataGridHeader
            value={value}
            handleFilter={handleFilter}
            onAddAction={toggleAddUserDrawer}
          />

          <BaseGrid autoHeight columns={columns} rows={rows} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default DataGrid;