import { AxiosResponse } from 'axios';

import axios, { privateAxios } from '../../../api/axios';

import { AuthModel, User } from '../core/_models';

const AUTH_URL = 'auth';
export const ACCESS_TOKEN_URL = AUTH_URL;
export const REGISTER_URL = `${AUTH_URL}/register`;
export const LOGIN_URL = `${AUTH_URL}/login`;
export const REFRESH_URL = `${AUTH_URL}/logout`;
export const LOGOUT_URL = `${AUTH_URL}/logout`;
export const ACCOUNT_URL = 'account';
export const PASSWORD_URL = `/password`;
export const PASSWORD_RECOVERY_URL = `${PASSWORD_URL}/recovery`;

// Server should return AuthModel
export function login(email: string, password: string): Promise<AuthModel> {
  return privateAxios
    .post(LOGIN_URL, { email, password }, { withCredentials: true })
    .then((response: AxiosResponse<AuthModel>) => response.data);
}

// Server should return AuthModel
export const register = async (
  firstName: string,
  lastName: string,
  email: string,
  password?: string,
  role?: string
): Promise<User> => {
  const response = await axios.post(REGISTER_URL, {
    firstName,
    lastName,
    email,
    password,
    role,
  });

  return response.data;
};

// Server should return object => { result: boolean } (Is Email in DB)
export const passwordRecovery = async (email: string) => {
  return await privateAxios.post<{ result: boolean }>(
    PASSWORD_RECOVERY_URL,
    {
      email,
    },
    { withCredentials: true }
  );
};

export function getUserByToken(): Promise<User> {
  return privateAxios
    .get(ACCESS_TOKEN_URL, { withCredentials: true })
    .then((response: AxiosResponse<User>) => response.data);
}

export const refresh = (): Promise<void> => {
  return privateAxios.post(REFRESH_URL, null, { withCredentials: true });
};

export const logout = (): Promise<void> => {
  return privateAxios.post(LOGOUT_URL, null, { withCredentials: true });
};

export const googleLogin = async (body: {
  code: string;
}): Promise<AuthModel> => {
  const response = await privateAxios.post(`${AUTH_URL}/google/login`, body, {
    withCredentials: true,
  });

  return response?.data;
};
