import { createContext, FC, useContext, useMemo, useState } from 'react';
import { WithChildren } from '../../core';
import { checkIfAllDataSelected, checkIfGroupingIsDisabled, groupingOnSelect, groupingOnSelectAll } from '../helpers';

import { ID, initialListView, ListViewContextProps } from '../models';
import { useResponseData, useResponseLoading } from './ResponseProvider';

const ListContext = createContext<ListViewContextProps>(initialListView);

export const ListViewProvider: FC<WithChildren> = ({ children }) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected);
  const [itemForUpdate, setItemForUpdate] = useState<ID>(initialListView.itemForUpdate);
  const isLoading = useResponseLoading();
  const data = useResponseData();
  const disabled = useMemo(() => checkIfGroupingIsDisabled(isLoading, data), [isLoading, data]);
  const isAllSelected = useMemo(() => checkIfAllDataSelected(data, selected), [data, selected]);

  return (
    <ListContext.Provider value={{
      clearSelection: () => setSelected([]),
      disabled,
      isAllSelected,
      itemForUpdate,
      onSelect: (id) => {
        groupingOnSelect(id, selected, setSelected);
      },
      onSelectAll: () => {
        groupingOnSelectAll(isAllSelected, setSelected, data);
      },
      selected,
      setItemForUpdate,
    }}>
      {children}
    </ListContext.Provider>
  );
};

export const useListView = () => useContext(ListContext);
